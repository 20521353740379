import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import MuiButton from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/Error'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import FileDropZone from './hooks/useFileDropZone'
import FILE_TYPES from '../../store/objects/data/fileTypes.json'
import ConfirmDialog from '../../components/dialogs/ConfirmDialog'
import MessageDialog from '../../components/dialogs/MessageDialog'
import SelectSourceDialog from './dataLake/selectSourceDialog'
import SelectEdpSourceDialog from './edp/SelectEdpSource'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import { fetchRoles } from '../../store/pages/dataLake'
import { tableRowBackGroundColor } from '../../components/table/table.utils'
import { changedFileTypeAction } from '../../store/pages/project/actions'
import { getAccess, deleteSource, deleteSourceFabric } from '../../store/pages/project/async'
import { fetchDataProducts } from '../../store/pages/edp'
import countries from '../../store/objects/data/countries.json'
const { REACT_APP_RGAENV: rgaEnv } = process.env

const FILE_TYPES_EMPTY = []
const FILE_TYPES_SKIPMAP = ['I', 'T', 'RH', 'TY']
const FILE_TYPES_SKIPMAP_REQUIRED = ['I', 'T']

const StyledGrid = styled(Grid)(({
  backgroundColor: 'white'
}))

const StyledTableRow = styled(TableRow)(({
  height: '5'
}))

const Button = styled(MuiButton)(({
  '&.MuiButtonBase-root.MuiButton-root': {
    marginBottom: 8,
    width: '100%',
    borderWidth: 2,
    border: 'black dashed',
    boxShadow: 'none',
    padding: 20,
    backgroundColor: 'white',
    color: 'black'
  },
  '&.MuiButtonBase-root.MuiButton-root:hover': {
    color: 'none'
  }
}))

const getLabel = (type, isSkipMap) => {
  switch (type) {
    case 'I':
      return isSkipMap ? 'Val Policy' : 'Inforce'

    case 'T':
      return isSkipMap ? 'Val Terminated' : 'Termination'

    case 'RH':
      return 'Risk History'

    case 'TY':
      return 'Treaty'

    default:
      return type
  }
}

const getFileTypes = (studyType) => {
  switch (studyType) {
    case '':
    case 'redi':
      return FILE_TYPES_EMPTY

    case 'skipMap':
      return FILE_TYPES_SKIPMAP

    default:
      throw new RangeError(`Studytype invalid: ${studyType}`)
  }
}

function getFileTypeOptions (isSkipMap, missingFileTypes, project) {
  const inforceTerminationFile = project.files.find(file => file.fileType === 'IT')
  const inforceFile = project.files.find(file => file.fileType === 'I')
  const terminationFile = project.files.find(file => file.fileType === 'T')

  if (isSkipMap) {
    return FILE_TYPES.skipMap.filter(fileTypeOption =>
      missingFileTypes.find(fileType =>
        fileType === fileTypeOption.value
      )
    )
  }

  if (inforceTerminationFile) {
    return FILE_TYPES.IT
  }

  return inforceFile?.uploaded || terminationFile?.uploaded
    ? FILE_TYPES.individualTypes
    : FILE_TYPES.default
}

FileList.propTypes = {
  source: PropTypes.object,
  handleChange: PropTypes.func
}

export default function FileList (props) {
  const dispatch = useDispatch()
  const global = useSelector(state => state.global)
  const user = useSelector(state => state.user)
  const project = useSelector(state => state.project)

  const isPOC = ['poc'].includes(rgaEnv)
  const isUSRegion = countries.some(country => country.value === project.country && country.region.includes('us'))
  const hasFiles = project.files.length > 0
  const isEDP = project.files.some(file => file.src === 'edp')

  const [open, setOpen] = React.useState(false)
  const [guid, setGuid] = React.useState('')
  const [source, setSource] = React.useState('')
  const [deletingSource, setDeletingSource] = React.useState(false)
  const [openFabricNoDelete, setOpenFabricNoDelete] = React.useState(false)

  const [openEdp, setOpenEdp] = React.useState(false)

  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'UPLOAD'
  )

  const deleteText = `Do you want to delete ${source}?`
  const isSkipMap = project.studyType === 'skipMap'
  const fileTypes = getFileTypes(project.studyType)

  const defaultFiles = React.useMemo(() => fileTypes.map(fileType => ({
    fileType,
    dataSet: null,
    columns: null,
    validation: null,
    dataFabric: null
  })), [fileTypes])

  const missingFileTypes = fileTypes.filter(fileType =>
    !project.files.find(file =>
      file.fileType === fileType
    )
  )

  const fileTypeOptions = getFileTypeOptions(isSkipMap, missingFileTypes, project)

  const filesData = React.useMemo(() => {
    if (!project.files || !project.files.length) {
      return defaultFiles
    }

    return project.studyType !== 'redi'
      ? defaultFiles.map(defaultFile => {
        const targetFile = project.files.find(file => (
          file.fileType === defaultFile.fileType)
        )
        return targetFile || defaultFile
      })
      : project.files.map(file => ({
        ...file,
        dataSet: file.fileName
      }))
  }, [defaultFiles, project.files, project.studyType])

  const handleAddDataFabricFilesClick = React.useCallback(() => {
    dispatch(fetchRoles)
    setOpen(true)
  }, [
    dispatch,
    setOpen
  ])

  const handleAddEDPClick = React.useCallback(() => {
    dispatch(fetchDataProducts(user.sid, project.country))
    setOpenEdp(true)
  }, [dispatch, user.sid, project.country])
  const handleSourceDelete = React.useCallback(async () => {
    setDeletingSource(false)

    if (isSkipMap) {
      await dispatch(deleteSourceFabric(source))
    } else {
      await dispatch(deleteSource(guid))
    }
  }, [
    isSkipMap,
    guid,
    source,
    dispatch
  ])

  const handleDelete = React.useCallback(event => {
    const index = Number(event.currentTarget.getAttribute('data-index'))
    const file = project.files[index]

    if (
      project.studyType === 'skipMap' &&
      FILE_TYPES_SKIPMAP_REQUIRED.includes(file.type)
    ) {
      setOpenFabricNoDelete(true)
    } else {
      setGuid(file.guid)
      setSource(file.fileName)
      setDeletingSource(true)
    }
  }, [
    project.studyType,
    project.files
  ])

  const handleSelectFileType = React.useCallback((option, event) => {
    dispatch({
      ...changedFileTypeAction,
      payload: {
        key: 'fileType',
        value: option.value,
        index: Number(event.name)
      }
    })
  }, [dispatch])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleCloseEdp = React.useCallback(() => {
    setOpenEdp(false)
  }, [setOpenEdp])

  const handleCloseFabricNoDelete = React.useCallback(() => {
    setOpenFabricNoDelete(false)
  }, [setOpenFabricNoDelete])

  return (
    <Grid>
      {access && (
        <ConfirmDialog
          data-testid='dialog-delete-confirm'
          open={deletingSource}
          title='Delete Source'
          text={deleteText}
          onCancel={() => setDeletingSource(false)}
          onConfirm={handleSourceDelete}
        />
      )}

      <MessageDialog
        data-testid='dialog-fabric-cannot-delete'
        open={openFabricNoDelete}
        title='Cannot Remove Dataset'
        text='This dataset is a required field. Please use the data source dialog to select a different dataset for this field.'
        onClose={handleCloseFabricNoDelete}
      />

      <StyledGrid >
        <Grid item xs={12}>
          <SelectSourceDialog
            open={open}
            fabricMapping={props.source.fabric}
            files={filesData}
            onClose={handleClose}
          />
          <SelectEdpSourceDialog
            openEdp={openEdp}
            currentProjectFiles={project.files}
            onClose={handleCloseEdp}
            studyType={project.studyType}
          />
          {project.country && project.studyType &&
            <Grid item xs>
              <FormControlLabel
                control={
                  <Grid marginLeft={'20px'} item xs>
                    {!isSkipMap &&
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="csv"
                            onChange={props.handleChange}
                            checked={props.source.csv}
                            disabled={hasFiles || project.id}
                            data-testid="csv-checkbox"
                          />
                        }
                        label="CSV"
                      />
                    }
                    {isUSRegion &&
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="fabric"
                            onChange={props.handleChange}
                            checked={props.source.fabric}
                            data-testid="fabric-checkbox"
                            disabled={hasFiles || project.id}
                          />
                        }
                        label="Fabric"
                      />
                    }
                    {!isPOC &&
                    <FormControlLabel
                      control={
                        <Checkbox name="edp"
                          onChange={props.handleChange}
                          checked={props.source.edp}
                          disabled={hasFiles || project.id}
                        />
                      }
                      label="EDP"
                    />
                      }
                  </Grid>
                }
              />

              {(props.source.fabric) && (
                <Button
                  data-testid='fabric-button'
                  variant='contained'
                  startIcon={<PlaylistAddIcon />}
                  onClick={handleAddDataFabricFilesClick}
                >
                  ADD DATA FABRIC FILES
                </Button>
              )}
              {(props.source.edp) && (
                <Button
                  data-testid='button-open'
                  variant='contained'
                  startIcon={<PlaylistAddIcon />}
                  onClick={handleAddEDPClick}
                >
                  ADD EDP FILES
                </Button>
              )}
            </Grid>
          }
          {access && props.source.csv && <FileDropZone />}
        </Grid>

        {project.files.length > 0
          ? <Grid item xs={12}>
            <Table size='small'>
              <TableHead>
                <StyledTableRow >
                  <TableCell></TableCell>
                  <TableCell>File Name</TableCell>
                  <TableCell>File Type</TableCell>
                  <TableCell>Status</TableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {project.files.map((row, index) => (
                  <TableRow
                    key={index}
                    style={tableRowBackGroundColor(index)}
                  >
                    <TableCell>
                      {access && (
                        <IconButton
                          data-testid={`button-delete-${index}`}
                          data-index={index}
                          disabled={global.isSaving}
                          size='large'
                          onClick={handleDelete}
                        >
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      )}
                    </TableCell>

                    <TableCell align='left'>
                      {row.fileName}
                    </TableCell>

                    <TableCell
                      align='left'
                      data-testid={`cell-fileType-${index}`}
                    >
                      {!row.src
                        ? (row.uploaded || global.isSaving
                            ? FILE_TYPES.default.find(option => option.value === row.fileType).label
                            : <Select
                            data-testid={`select-fileType-${index}`}
                            data-index={index}
                            index={index}
                            name={index}
                            options={fileTypeOptions}
                            onChange={handleSelectFileType}
                          />
                          )
                        : <div
                          data-testid={`row-get-label-${index}`}
                        >
                          {getLabel(row.fileType, isSkipMap)}
                        </div>
                      }
                    </TableCell>

                    <TableCell>
                      {
                      isEDP && isSkipMap
                        ? row.edpStatus === 'processing'
                          ? <div
                            data-testid='uploading'
                          >
                            UPLOADING
                          </div>
                          : row.edpStatus === 'succeeded'
                            ? <div> SUCCEEDED </div>
                            : <div></div>
                        : row.isProcessing
                          ? row.uploaded
                            ? <div
                            data-testid='profiling'
                          >
                            Profiling

                            <LinearProgress color='primary' />
                          </div>
                            : <div
                            data-testid='uploading'
                          >
                            Uploading

                            <LinearProgress
                              variant='determinate'
                              value={row.uploadProgress}
                              color='primary'
                            />
                          </div>
                          : <div
                          data-testid={'profileStatus'}
                        >
                          {row.profileStatus || ''}

                          {row.profileStatus === 'Profiling'
                            ? <LinearProgress color='primary' />
                            : ''
                          }

                          {row.messageError && (
                            <Tooltip
                              data-testid='tooltip-error'
                              title={row.messageError}
                            >
                              <ErrorIcon color='primary' />
                            </Tooltip>
                          )}
                        </div>

                      }
                    </TableCell>
                  </TableRow>
                )
                )}
              </TableBody>
            </Table>
          </Grid>
          : <div data-testid='label-no-files'></div>
        }
      </StyledGrid>
    </Grid>
  )
}
