import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import uuid from '../../../../uuid'
import useForm from '../../../../hooks/useForm'
import calculationApi from '../../../../store/objects/calculationApi'
import projectApi from '../../../../store/objects/projectApi'
import stepFunctionsApi from '../../../../store/objects/stepFunctionsApi'
import SelectableListView from '../SelectableListView'
import Parameters from './Parameters'
import RunStudy from './RunStudy'
import Ibnr from './Ibnr'
import { styled } from '@mui/material'
import { traverse } from '@zensen/form-service/build/utils'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateRunSetupAction } from '../../../../store/pages/calculation/actions'
import { getJobHistory } from '../../../../store/pages/calculation/async'
import { redePostCalChecks } from '../../../../store/pages/postCalculation'
import { loadedProjectDataAction } from '../../../../store/pages/project/actions'
import { getAccess } from '../../../../store/pages/project/async'

const PARAMETER_KEYS = [
  'applyIncidenceRateCap',
  'addMonthlyAggregation',
  'applySubstandardFlatExtra',
  'addBuhlmannConstants',
  'applyExpectedLapseRates',
  'includeWarnings',
  'performMonteCarlo',
  'calculateIbnr',
  'treatyRestrictions',
  'policyRestrictions',
  'periodStartDate',
  'periodEndDate'
]

const IBNR_PARAMS = [
  'asOfDate',
  'monthsPrior',
  'aggregators',
  'caseNumber'
]

const INITIAL_JOB = {
  totalTime: null,
  fileProcessingTime: null,
  xpCalculatorRunTime: null,
  workWorthPerNode: null,
  numberOfNodes: null,
  dataLakeLoadTime: null,
  valPolicySize: null,
  valTerminateSize: null,
  riskHistorySize: null,
  treatySize: null,
  basisSize: null,
  packageName: null,
  errorMessage: '',
  createdDate: null,
  deletedDate: null,
  tableNames: []
}

/* istanbul ignore next */
const CONFIG_AGGREGATORS_VIEW = [
  {
    key: 'AGGREGATOR_NAME',
    label: 'Name',
    size: 100
  },
  {
    key: 'POPULARITY',
    label: 'Popularity',
    size: 100,
    render: value => (
      <LinearProgress
        variant='determinate'
        value={value}
      />
    )
  },
  {
    key: 'FIELDS_USED',
    label: 'Fields',
    size: 200
  },
  {
    key: 'IBNR_ENABLED',
    label: 'IBNR Enabled',
    size: 100
  },
  {
    key: 'DESCRIPTION',
    label: 'Description',
    size: 200,
    render: value => <Tooltip title={value}><span></span></Tooltip>
  }
]

/* istanbul ignore next */
const CONFIG_EXPECTED_BASIS_VIEW = [
  {
    key: 'caseNumber',
    label: 'Case #',
    size: 100
  },
  {
    key: 'name',
    label: 'Name',
    size: 200
  },
  {
    key: 'description',
    label: 'Description',
    size: 200
  }
]

const MODEL = {
  bases: SelectableListView.createModel(),
  aggregators: SelectableListView.createModel(),
  parameters: Parameters.createModel(),
  ibnr: Ibnr.createModel()
}

const SELECTORS = {
  unformat: v => ({
    id: uuid(),
    ...v
  }),
  children: {
    bases: SelectableListView.createSelectors(),
    aggregators: SelectableListView.createSelectors(),
    parameters: Parameters.createSelectors(),
    ibnr: Ibnr.createSelectors()
  }
}

const SpinnerContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '25%',
  marginLeft: '50%'
})

const SpinnerContent = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  transform: 'translate(-50%, -50%)'
})

function flattenErrors (errors) {
  const result = []

  traverse(errors, (_keyPath, v) => {
    if (typeof v === 'string') {
      result.push(v)
    }
  })

  return result
}

async function fetchDates (project) {
  const { data } = await calculationApi.getRunStudyValues(project.id)
  const endDateParts = data[0].profiledate.substring(0, 10).split('-')
  const endDate = new Date(Number(endDateParts[0]), Number(endDateParts[1]), 0)

  return {
    observationDate: data[0].profiledate.substring(0, 10),
    periodStartDate: data[1].profiledate.substring(0, 10),
    periodEndDate: endDate.toISOString().substring(0, 10)
  }
}

async function fetch (project) {
  const [dates, jobs, aggregators] = await Promise.all([
    fetchDates(project),
    calculationApi.fetchJobs(project.id),
    calculationApi.fetchAggregators()
  ])

  return {
    dates,
    jobs,
    aggregators
  }
}

function Spinner () {
  return (
    <SpinnerContainer data-testid='spinner-loading'>
      <SpinnerContent>
        <Typography>Loading</Typography>
        <CircularProgress />
      </SpinnerContent>
    </SpinnerContainer>
  )
}

const MemoizedSpinner = React.memo(Spinner)

export default function Study () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [disableActionButton, setDisableActionButton] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [jobs, setJobs] = React.useState(null)

  const [aggregators, setAggregators] = React.useState(null)
  const [deletingJobIds, setDeletingJobIds] = React.useState([])
  const [updatingJobId, setUpdatingJobId] = React.useState(null)
  const [submittedJob, setSubmittedJob] = React.useState(null)
  const [postCalcProgress, setPostCalcProgress] = React.useState(null)
  const user = useSelector((store) => store.user)
  const project = useSelector((store) => store.project)
  const currentTab = useSelector((store) => store.calculation.currentTab)
  const postCalculationCheckRunning = useSelector((store) => store.postCalc.postCalculationCheckRunning)
  const bases = useSelector((store) => store.refData.basis)
  const valTermColumns = project.formatFiles.find(file => file.type === 'val terminated').columns

  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'CALCULATION'
  )

  const form = useForm(MODEL, SELECTORS, (_dirty, state) => {
    dispatch({
      ...updateRunSetupAction,
      payload: {
        runSetup: {
          bases: state.bases,
          aggregators: state.aggregators,
          parameters: state.parameters,
          ibnr: state.ibnr
        }
      }
    })
  })

  const errorMessage = [
    !access ? 'You do not have access to this feature' : '',
    ...flattenErrors(form.errors)
  ].filter(item => item)[0] || ''

  const waitForJob = React.useCallback(async job => {
    setSubmittedJob(job)

    try {
      const res = await stepFunctionsApi.waitForCompletion(job.executionArn)

      if (res.status === 'ABORTED') {
        throw new Error('Aborted')
      }

      const output = res.output ? JSON.parse(res.output) : ''
      const outputFiles = output?.fileNames ?? []
      const isIBNRSuccess = job.ibnr ? output?.ibnrSuccess : null

      if (outputFiles.length === 0) {
        throw new Error('No output detected')
      }

      const updatedJob = isIBNRSuccess
        ? {
            ...job,
            finished: true,
            tableNames: [...outputFiles]
          }
        : {
            ...job,
            finished: true,
            tableNames: [...outputFiles],
            isIBNRSuccess
          }

      setJobs(prev => [
        updatedJob,
        ...prev
      ])

      setSubmittedJob(null)

      return true
    } catch (err) {
      const isIBNRSuccess = job.ibnr ? false : null
      setJobs(prev => [
        {
          ...job,
          finished: true,
          errorMessage: err.message,
          tableNames: [],
          isIBNRSuccess
        },
        ...prev
      ])

      setSubmittedJob(null)

      return false
    }
  }, [setSubmittedJob, setJobs])

  const handlePopulate = React.useCallback(job => {
    const params = job.parameters
    const ibnr = job.ibnr

    form.apply('bases', job.bases)
    form.apply('aggregators', job.aggregators)
    PARAMETER_KEYS.forEach(key => form.apply(`parameters.${key}`, params[key]))
    IBNR_PARAMS.forEach(key => form.apply(`ibnr.${key}`, ibnr[key]))
  }, [form])

  const handleDeleteJob = React.useCallback(async job => {
    setDeletingJobIds(prev => [...prev, job.id])

    await calculationApi.deleteJobRun(project.id, job.id)

    const updatedProject = await projectApi.getProject(project.id)

    dispatch({
      ...loadedProjectDataAction,
      payload: {
        project: updatedProject,
        navigation: {}
      }
    })

    setDeletingJobIds(prev => prev.filter(targetId => targetId !== job.id))
    setJobs(prev => prev.filter(item => item.id !== job.id))
  }, [
    project.id,
    dispatch
  ])

  const handleToggleJob = React.useCallback(async job => {
    const isOfficial = project.officialJobId === job.id
    const officialJobId = isOfficial ? null : job.id

    const modifiedProject = {
      ...project,
      officialJobId
    }

    setUpdatingJobId(job.id)

    const updatedProject = await projectApi.putProject(modifiedProject)

    dispatch({
      ...loadedProjectDataAction,
      payload: {
        project: updatedProject,
        navigation: {}
      }
    })

    setUpdatingJobId(() => null)
  }, [
    project,
    dispatch
  ])

  const handleSubmit = React.useCallback(async () => {
    if (!form.validate()) {
      return
    }

    const model = form.build()

    setDisableActionButton(true)

    const res = await calculationApi.submitJob(user, project, model)

    setDisableActionButton(false)

    const job = {
      ...model,
      ...INITIAL_JOB,
      executionArn: res.executionArn
    }

    if (await waitForJob(job)) {
      await dispatch(getJobHistory)
      await dispatch(redePostCalChecks(job.id))
      navigate(`/postcalculation/${project.id}`)
    }
  }, [
    form,
    user,
    project,
    dispatch,
    navigate,
    waitForJob
  ])

  const handleAbort = React.useCallback(async () => {
    setDisableActionButton(true)
    await calculationApi.abortJob(project.id, submittedJob.id)
    setSubmittedJob(null)
    setDisableActionButton(false)
  }, [project.id, submittedJob?.id])

  React.useEffect(() => {
    if (postCalculationCheckRunning) {
      setPostCalcProgress('Running')
    }
  }, [postCalculationCheckRunning])

  React.useEffect(() => {
    dispatch(getJobHistory).then(async () => {
      const res = await fetch(project)
      const latestJob = res.jobs[0] || null
      const running = latestJob ? !latestJob.finished : false
      const finishedJobs = running ? res.jobs.slice(1) : res.jobs
      const baseModel = { ...MODEL }
      const latestParams = latestJob?.parameters || null
      const latestIBNRParams = latestJob?.ibnr || null

      const params = {
        ...baseModel.parameters,
        ...latestParams
      }

      const ibnrParams = { ...baseModel.ibnr, ...latestIBNRParams }

      setJobs(finishedJobs)
      setAggregators(res.aggregators)

      form.refresh({
        bases: latestJob?.bases || baseModel.bases,
        aggregators: latestJob?.aggregators || baseModel.aggregators,
        parameters: {
          ...params,
          observationDate: params.observationDate ?? res.dates.observationDate,
          periodStartDate: params.periodStartDate ?? res.dates.periodStartDate,
          periodEndDate: params.periodEndDate ?? res.dates.periodEndDate,
          powerBiReportUrl: params.powerBiReportUrl ?? res.jobs[0].parameters.powerBiReportUrl
        },
        ibnr: {
          ...ibnrParams,
          asOfDate: params.observationDate ?? res.dates.observationDate
        }
      })

      setLoaded(true)

      if (running) {
        waitForJob(latestJob)
      }
    })
  }, [dispatch, form, project, waitForJob])

  if (!loaded) {
    return <MemoizedSpinner />
  }

  return (
    <TabContext value={currentTab}>
      <TabPanel value='Parameters'>
        <Parameters
          data-testid='parameters'
          name='parameters'
          access={access}
          value={form.state.parameters}
          errors={form.errors.parameters}
          onChange={form.apply}
        />
      </TabPanel>

      <TabPanel value='Aggregators'>
        <SelectableListView
          data-testid='list-view-aggregators'
          name='aggregators'
          maxSelectCount={50}
          sortKey='POPULARITY'
          sortOrder='desc'
          selectKey='KEY_ALIAS'
          searchKey='KEY_ALIAS'
          config={CONFIG_AGGREGATORS_VIEW}
          items={aggregators}
          selectedItems={form.state.aggregators}
          onChange={form.apply}
        />
      </TabPanel>

      <TabPanel value='Expected Bases'>
        <SelectableListView
          data-testid='list-view-bases'
          name='bases'
          sortKey='caseNumber'
          selectKey='caseNumber'
          searchKey='caseNumber'
          config={CONFIG_EXPECTED_BASIS_VIEW}
          items={bases}
          selectedItems={form.state.bases}
          onChange={form.apply}
        />
      </TabPanel>

      <TabPanel value='IBNR'>
        <Ibnr
          data-testid='ibnr'
          name='ibnr'
          allBases={bases}
          selectedBases={form.state.bases}
          selectedAggregators={form.state.aggregators}
          allAggregators={aggregators}
          valTermColumns={valTermColumns}
          value={form.state.ibnr}
          onChange={form.apply}
          access={access}
        />
      </TabPanel>

      <TabPanel value='Run Study'>
        <RunStudy
          data-testid='study'
          disableActionButton={disableActionButton}
          officialJobId={project.officialJobId}
          errorMessage={errorMessage}
          updatingId={updatingJobId}
          deletingIds={deletingJobIds}
          jobs={jobs}
          value={form.state}
          submittedJob={submittedJob}
          postCalcProgress={postCalcProgress}
          onPopulate={handlePopulate}
          onAbort={handleAbort}
          onSubmit={handleSubmit}
          onToggle={handleToggleJob}
          onDelete={handleDeleteJob}
        />
      </TabPanel>
    </TabContext>
  )
}
