import PropTypes from 'prop-types'
import React from 'react'
import ReactSelect from 'react-select'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Link from '@mui/material/Link'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import StopIcon from '@mui/icons-material/Stop'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { resetAction, fetchViews, fetchColumns, validateMetadata } from '../../../store/pages/edp'
import Tooltip from '@mui/material/Tooltip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { addedEdpSourceAction } from '../../../store/pages/project/actions'
import ViewEdpTables from './ViewEdpTables'
const Select = styled(ReactSelect)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}))

const renderValidationCell = (row) => {
  const { validation = null } = row
  if (validation === 'loading' || validation === null) {
    return validation === 'loading'
      ? <CircularProgress data-testid='circle-progress'/>
      : <div data-testid='empty'> {'-'} </div>
  } else {
    return validation === 'successful' || validation === ' '
      ? <Tooltip
          data-testid='tooltip-success'
          title={'Valid'}
        >
    <CheckCircleIcon
      data-testid='check-circle'
      fontSize="large"
      style={{ color: '#6FE52A' }}/>
    </Tooltip>
      : <Tooltip
          data-testid='tooltip-failure'
          title={row.validation}>
          <ErrorIcon
            data-testid='check-error'
            fontSize="large"
            color='primary'
          />
      </Tooltip>
  }
}
const OPTIONS_FILE_SKIP_MAP = [
  {
    label: 'val policy',
    value: 'I'
  },
  {
    label: 'val terminated',
    value: 'T'
  },
  {
    label: 'evexp risk history',
    value: 'RH'
  },
  {
    label: 'treaty',
    value: 'TY'
  }
]

export default function SelectEdpSource ({ openEdp, onClose, defaultFiles }) {
  const dispatch = useDispatch()
  const [currentDataProduct, setCurrentDataProduct] = React.useState(null)
  const edp = useSelector(store => store.edp)
  const projectId = useSelector(store => store.project.id)
  const projectFiles = useSelector(store => store.project.files)
  const projectStudyType = useSelector(store => store.project.studyType)
  const projectCountry = useSelector(store => store.project.country)
  const [edpViews, setViewsEdp] = React.useState([])
  const [isReadyToReset, setIsReadyToReset] = React.useState(false)
  const isMissingDataSets = projectFiles.length !== 4
  const currentFiles = projectId ? projectFiles : defaultFiles

  const rediFileTypeValidation = (filetype) => {
    const required = viewsSelected.filter(file => (file.type === 'T' || file.type === 'I') &&
      file.dataSet) >= 0

    if (projectStudyType === 'skipMap') {
      return (filetype === 'T' || filetype === 'I') && required
        ? `${filetype} (** Required)`
        : `${filetype}`
    } else {
      return `${filetype}`
    }
  }

  const [viewsSelected, setViewsSelected] = React.useState(currentFiles)
  const schemasDataProducts = (edp.dataProducts || []).map(dataProduct => ({
    value: dataProduct,
    label: dataProduct
  }))
  const defaultFilesEdp = React.useMemo(() => projectStudyType === 'skipMap'
    ? OPTIONS_FILE_SKIP_MAP
      .map(file => ({ fileName: null, type: file.value, validation: null }))
    : [], [projectStudyType])

  React.useEffect(() => {
    if (openEdp) {
      if (projectId && projectStudyType === 'skipMap' && isMissingDataSets) {
        const projectFilesTypes = projectFiles.map(x => x.type)
        const newfiles = defaultFilesEdp.filter(defaultFile => !projectFilesTypes.find(type => type === defaultFile.type))
        const projectSkipMapUpdate = [...projectFiles, ...newfiles]
        setViewsSelected(projectSkipMapUpdate)
      } else {
        setViewsSelected(projectId ? projectFiles : defaultFilesEdp)
      }
    }
  }, [openEdp, projectId, projectFiles, defaultFilesEdp, projectStudyType, isMissingDataSets])

  const handleRemoveView = React.useCallback((viewToRemove, index) => {
    const newViews = [...viewsSelected]
    if (projectStudyType === 'skipMap') {
      newViews[index] = { ...newViews[index], dataProduct: null, fileName: null, columns: null, validation: null }
    } else {
      newViews.splice(index, 1)
    }
    setViewsSelected(newViews)
  }, [projectStudyType, viewsSelected])

  const handleAddEdpTableSkipMap = async (edpView) => {
    const newView = {
      fileName: edpView.fileName,
      dataProduct: currentDataProduct.value,
      columns: [],
      type: edpView.type,
      validation: 'loading'
    }
    setViewsSelected((prevViews) => prevViews.map((view) =>
      view.type === edpView.type ? newView : view
    ))
    const columnsPerFile = await fetchColumns(currentDataProduct, edpView, projectCountry)
    setViewsSelected((prevViews) => prevViews.map((view) =>
      view.type === edpView.type ? newView : view
    ))

    const { validationTooltip = 'Successful' } = await dispatch(
      validateMetadata(columnsPerFile, edpView.type)
    )

    const index = viewsSelected.findIndex((view) => view.type === edpView.type)
    setViewsSelected((prevViews) => {
      const updatedItem = {
        ...prevViews[index],
        validation: validationTooltip,
        columns: columnsPerFile
      }
      return [
        ...prevViews.slice(0, index),
        updatedItem,
        ...prevViews.slice(index + 1)
      ]
    })
    setIsReadyToReset(true)
  }

  const handleAddEdpTableRedi = async (edpView) => {
    const newView = {
      fileName: edpView.fileName,
      dataProduct: currentDataProduct.value,
      type: edpView.type,
      validation: 'loading'
    }

    setViewsSelected((prevViews) => {
      const existingViewIndex = prevViews.findIndex(view => view.fileName === edpView.fileName)
      if (existingViewIndex !== -1) {
        const updatedViews = [...prevViews]
        updatedViews[existingViewIndex] = newView
        return updatedViews
      } else {
        return [...prevViews, newView]
      }
    })
    const columnsPerFile = await fetchColumns(currentDataProduct, edpView, projectCountry)
    const updateNewview = { ...newView, columns: columnsPerFile, validation: 'successful' }
    setViewsSelected((prevViews) => {
      const existingViewIndex = prevViews.findIndex(view => view.fileName === edpView.fileName)
      if (existingViewIndex !== -1) {
        const updatedViews = [...prevViews]
        updatedViews[existingViewIndex] = updateNewview
        return updatedViews
      } else {
        return [...prevViews, updateNewview]
      }
    })
    setIsReadyToReset(true)
  }
  React.useEffect(() => {
    if (edp?.views?.length > 0 || isReadyToReset) {
      setViewsEdp(([...edp.views] || []).map(currentView => ({ fileName: currentView, type: null })))
      setIsReadyToReset(false)
    }
  }, [edp, isReadyToReset])

  const handleDataProductSelected = React.useCallback(schemaOption => {
    setCurrentDataProduct(schemaOption)
    dispatch(fetchViews(schemaOption, projectCountry))
  }, [dispatch, projectCountry])

  const handleClose = React.useCallback((_event, backDrop) => {
    onClose()
    setViewsSelected([])
    setViewsEdp([])
    setCurrentDataProduct(null)
    dispatch(resetAction)
  }, [
    dispatch,
    onClose
  ])
  const validateFiles = (viewsToSave, studyType) => {
    if (studyType === 'skipMap' && viewsToSave) {
      const requiredViews = viewsToSave.filter(view => view.type === 'I' || view.type === 'T')

      if (requiredViews.some(view => view.validation !== 'successful')) {
        return false
      }
    }
    return viewsToSave?.length !== 0
  }
  const allFilesValid = validateFiles(viewsSelected, projectStudyType)

  const handleSaveClick = React.useCallback(() => {
    dispatch({
      ...addedEdpSourceAction,
      payload: [...viewsSelected.filter(viewSelected => viewSelected.fileName && !viewSelected.guid)]
    })

    handleClose()
    onClose()
    dispatch(resetAction)
  }, [dispatch, handleClose, onClose, viewsSelected])

  const handleAddFunction = projectStudyType === 'skipMap'
    ? handleAddEdpTableSkipMap
    : handleAddEdpTableRedi

  return (
    <Dialog
      data-testid='dialog'
      fullWidth
      open={openEdp}
      maxWidth='lg'
      onClose={handleClose}
    >
      <DialogTitle
        data-testid='title'
        id='select-edp-dialog-title'
      >
        Add EDP files to the project
      </DialogTitle>

      <Divider />

      <DialogContent
        data-testid='content'
        style={{ overflowY: 'visible' }}
      >
        <Grid container spacing={2}>
          {edp?.dataProducts?.length > 0
            ? <Grid item xs={12}>
              <Typography variant='h6'>
                Select DataProduct
              </Typography>
              <Select
                data-testid='select-dataProduct'
                menuPortalTarget={document.querySelector('select-products-dialog-title')}
                isClearable
                styles={SingleValueStyles}
                components={{ SingleValue }}
                options={schemasDataProducts}
                value={currentDataProduct}
                onChange={handleDataProductSelected}
              />
            </Grid>
            : <>
              {!edp.dataProducts
                ? <React.Fragment> Checking EDP schema Access
                  <CircularProgress data-testid='progress' />
                  </React.Fragment>

                : (
                  <Link
                    data-testid='Link-no-dataProducts'
                    href='https://rga.service-now.com/rsp?kb_id=12c6dfbf1bc9ec10a283ececbc4bcb14&id=kb_view2'
                    target='_blank'
                  >
                  You do not have any EDP team spaces assigned, please click here to get help with this issue.
                  </Link>
                  )
              }
            </>
          }
           <Grid item xs={6}>
          {edp?.views?.length > 0 &&
        <ViewEdpTables
        studyType={projectStudyType}
        handleAddEdpTable={handleAddFunction}
        viewsSelected={viewsSelected}
        setViewsEdp={setViewsEdp}
        edpViews={edpViews}
        />
          }
           </Grid>
           {edp?.dataProducts?.length > 0 &&
          <Grid item xs={6}>
            <Typography variant='h6'>
              Selected Views to Import
            </Typography>
             <Table aria-labelledby="tableTitle" size='small'>
              <TableHead>
                <TableRow style={{ tableLayout: 'fixed' }}>
                <TableCell
                  width="40%"
                  style={{
                    whiteSpace: 'normal',
                    wordBreak: 'break-word'
                  }}
                >View
                </TableCell>
                <TableCell
                  width="20%"
                  style={{
                    whiteSpace: 'normal',
                    wordBreak: 'break-word'
                  }}
                >Data Product
                </TableCell>
                <TableCell
                  width="20%"
                  style={{
                    whiteSpace: 'normal',
                    wordBreak: 'break-word'
                  }}
                >Type
                </TableCell>
                <TableCell>Validation</TableCell>
                 <TableCell
                  width="20%"
                  style={{
                    whiteSpace: 'normal',
                    wordBreak: 'break-word'
                  }}
                >Remove
                </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewsSelected && viewsSelected.map((edpViewProps, i) => (
                  <TableRow key={i}>
                     <TableCell data-testid={`selected-view-${edpViewProps.fileName}`}
                      width="40%"
                      style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                    >{edpViewProps.fileName}
                    </TableCell>
                     <TableCell
                        width="20%"
                        style={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word'
                        }}
                      >{edpViewProps.dataProduct}
                      </TableCell>
                     <TableCell
                      width="20%"
                      style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                    >{rediFileTypeValidation(edpViewProps.type)}
                    </TableCell>
                    <TableCell >{renderValidationCell(edpViewProps)}</TableCell>
                    <TableCell
                      width="20%"
                      style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                    >
                    <IconButton
                      data-testid='button-delete'
                      onClick={() => handleRemoveView(edpViewProps.fileName, i)}
                      aria-label="delete"
                      size="large"
                    ><DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          data-testid='button-cancel-edp'
          variant='outlined'
          color='primary'
          startIcon={<StopIcon />}
          onClick={handleClose}
        >Cancel</Button>

        <Button
          disabled={!allFilesValid}
          data-testid='button-save'
          variant='contained'
          color='secondary'
          startIcon={<PlayArrowIcon />}
          onClick={handleSaveClick}
        >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

SelectEdpSource.propTypes = {
  openEdp: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultFiles: PropTypes.array,
  studyType: PropTypes.string
}
