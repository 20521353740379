import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'antd/dist/reset.css'
import PropTypes from 'prop-types'
import React from 'react'
import LineChart from '../../components/NivoChart/LineChart'
import PieChart from '../../components/NivoChart/PieChart'
import FunnelChart from '../../components/NivoChart/FunnelChart'
import { AgGridReact } from 'ag-grid-react'
import { styled } from '@mui/material'

const DEFAULT_COL_DEFS = {
  resizable: true,
  minWidth: 90,
  flex: 1
}

const TableOuterContainer = styled('div')({
  height: '100%',
  overflow: 'auto'
})

const TableInnerContainer = styled('div')({
  width: '100%',
  height: '100%'
})

const ChartContainer = styled('div')({
  height: 445
})

function Table (props) {
  const gridRef = React.useRef()

  return props.data && (
    <div>
      {props.title && (
        <center>
          <h2>{props.title}</h2>
        </center>
      )}

      <TableOuterContainer>
        <TableInnerContainer className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            cacheBlockSize={20}
            domLayout='autoHeight'
            rowSelection='single'
            rowModelType='clientSide'
            defaultColDef={DEFAULT_COL_DEFS}
            columnDefs={props.columnDefs}
            rowData={props.data}
            enableRangeSelection={true}
          />
        </TableInnerContainer>
      </TableOuterContainer>
    </div>
  )
}

Table.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columnDefs: PropTypes.array.isRequired
}

function Chart (props) {
  switch (props.title) {
    case 'Risk Count':
    case 'Risk Amount':
      return <LineChart
        title={props.title}
        data={props.data}
      />

    case 'Policy Tracker':
      return <PieChart
        title={props.title}
        data={props.data}
      />

    case 'Lapse Exclusion':
      return <FunnelChart
        title={props.title}
        data={props.data}
      />

    default:
      return <div>Invalid chart: {props.title}</div>
  }
}

Chart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}

export default function Figure (props) {
  return props.showChart
    ? (
      <ChartContainer>
        <Chart
          title={props.title}
          data={props.data}
        />
      </ChartContainer>
      )
    : (
      <Table
        title={props.title}
        data={props.data}
        columnDefs={props.columnDefs}
      />
      )
}

Figure.propTypes = {
  showChart: PropTypes.bool,
  title: PropTypes.string,
  columnDefs: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
}

Figure.defaultProps = {
  showChart: false,
  title: ''
}
