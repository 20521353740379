import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import SelectRunCard from './components/SelectRunCard'
import Accordion from './Accordion'
import Figure from './Figure'
import { styled } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useDispatch } from 'react-redux'
import { replaceIbnr } from '../../store/pages/postCalculation'
import ReplaceIcon from '@mui/icons-material/ChangeCircleOutlined'

const REGEX_TABLE_EXP = /_exp/i

const INITIAL_GRAPH_STATE = {
  exposureVsRisk: false,
  policyTracker: false
}

const COL_DEFS_EXP_VS_RISK_AMOUNT = [
  { field: 'date', headerName: 'Date', suppressHeaderMenuButton: true },
  { field: 'exposure', headerName: 'Exposure Amount', suppressHeaderMenuButton: true },
  { field: 'risk history', headerName: 'Risk History Amount', suppressHeaderMenuButton: true }
]

const COL_DEFS_EXP_VS_RISK_COUNT = [
  { field: 'date', headerName: 'Date', suppressHeaderMenuButton: true },
  { field: 'exposure', headerName: 'Exposure Count', suppressHeaderMenuButton: true },
  { field: 'risk history', headerName: 'Risk History Count', suppressHeaderMenuButton: true }
]

const COL_DEFS_LAPSE_CHECK = [
  { field: 'year', headerName: 'Year', suppressHeaderMenuButton: true },
  { field: 'month', headerName: 'Month', suppressHeaderMenuButton: true },
  { field: 'cnt', headerName: 'Count', suppressHeaderMenuButton: true }
]

const COL_DEFS_CLAIM_VALIDATION = [
  { field: 'title', headerName: '', suppressHeaderMenuButton: true },
  { field: 't_count', headerName: 'Terminating Claims Count', suppressHeaderMenuButton: true },
  { field: 't_amount', headerName: 'Terminating Claims Amount', suppressHeaderMenuButton: true },
  { field: 'nt_count', headerName: 'Non Terminating Claims Count', suppressHeaderMenuButton: true },
  { field: 'nt_amount', headerName: 'Non Terminating Claims Amount', suppressHeaderMenuButton: true }
]

const COL_DEFS_CLAIMS_TERMINATE = [
  { field: 'event_year', headerName: 'Year', suppressHeaderMenuButton: true },
  { field: 'claim_count', headerName: 'Claim Count', suppressHeaderMenuButton: true },
  { field: 'claim_amount', headerName: 'Claim Amount', suppressHeaderMenuButton: true }
]

const COL_DEFS_CLAIMS_NON_TERMINATE = [
  { field: 'event_year', headerName: 'Year', suppressHeaderMenuButton: true },
  { field: 'claim_count', headerName: 'Claim Count', suppressHeaderMenuButton: true },
  { field: 'claim_amount', headerName: 'Claim Amount', suppressHeaderMenuButton: true }
]

const COL_DEFS_CLAIM_OUTPUT = [
  { field: 'calendar_year', headerName: 'Year', suppressHeaderMenuButton: true },
  { field: 'claim_count', headerName: 'Claim Count', suppressHeaderMenuButton: true },
  { field: 'claim_amount', headerName: 'Claim Amount', suppressHeaderMenuButton: true }
]

const COL_DEFS_CLAIMS_Q_VS_TERMINATE = [
  { field: 'calendar_year', headerName: 'Calendar Year', suppressHeaderMenuButton: true },
  { field: 'amount%', headerName: 'Amount %', suppressHeaderMenuButton: true },
  { field: 'amount difference', headerName: 'Claim Amount', suppressHeaderMenuButton: true },
  { field: 'count%', headerName: 'Count %', suppressHeaderMenuButton: true },
  { field: 'count difference', headerName: 'Claim Count', suppressHeaderMenuButton: true }
]

const COL_DEFS_POLICY_TRACKER = [
  { field: 'policy_status', headerName: '', suppressHeaderMenuButton: true },
  { field: 'policy_count', headerName: 'Count', suppressHeaderMenuButton: true },
  { field: 'policy_total', headerName: 'Percent', suppressHeaderMenuButton: true }
]

const COL_DEFS_IBNR = [
  { field: 'allAggs', headerName: 'All Aggregators', width: 200, suppressHeaderMenuButton: true },
  { field: 'calendar_year', headerName: 'Calendar Year', width: 200, suppressHeaderMenuButton: true },
  { field: 'calendar_month', headerName: 'Calendar Month', width: 200, suppressHeaderMenuButton: true },
  { field: 'claim_amt', headerName: 'Claim Amount', width: 200, suppressHeaderMenuButton: true },
  { field: 'claim_cnt', headerName: 'Claim Count', width: 200, suppressHeaderMenuButton: true },
  { field: 'exposure_amt', headerName: 'Exposure Amount', width: 200, suppressHeaderMenuButton: true },
  { field: 'exposure_cnt', headerName: 'Exposure Count', width: 200, suppressHeaderMenuButton: true },
  { field: 'ibnr_amt', headerName: 'IBNR Amount', width: 200, suppressHeaderMenuButton: true },
  { field: 'ibnr_cnt', headerName: 'IBNR Count', width: 200, suppressHeaderMenuButton: true },
  {
    filed: 'newIbnrAmt',
    headerName: 'New IBNR Amount',
    width: 200,
    editable: true,
    suppressHeaderMenuButton: true,
    valueGetter: params => {
      return params.data.newIbnrAmt;
    },

    valueSetter: params => {
      params.data.newIbnrAmt = params.newValue;
      return true;
    }
  },
  {
    filed: 'newIbnrCnt',
    headerName: 'New IBNR Count',
    width: 200,
    editable: true,
    suppressHeaderMenuButton: true,
    valueGetter: params => {
      return params.data.newIbnrCnt;
    },

    valueSetter: params => {
      params.data.newIbnrCnt = params.newValue;
      return true;
    }
  }
]

const LoadingLabel = styled('h3')({
  marginTop: '0',
  padding: '1em',
  color: 'black'
})

const Grid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr 1fr'
}))

const Row = styled(Box)({
  gridColumn: '1 / -1'
})

const StyledButton = styled(LoadingButton)({
  float: 'right',
  margin: '1em'
})

function formatNumber (value) {
  return parseFloat(value)
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getFormattedCount (rows) {
  if (!rows) {
    return '0'
  }

  const count = rows.reduce(
    (accum, curr) => accum + Number(curr.claim_count),
    0
  )

  return formatNumber(count)
}

function getFormattedAmount (rows) {
  if (!rows) {
    return '0'
  }

  const count = rows.reduce(
    (accum, curr) => accum + Number(curr.claim_amount),
    0
  )

  return formatNumber(count)
}

function IBNRCheck (selectedJob) {
  if (selectedJob) {
    return selectedJob.parameters.calculateIbnr === true
  }
}

function buildClaimValidationData (postCalc) {
  const terminating = postCalc.claimsTerminatingData || []
  const nonTerminating = postCalc.claimsNonTerminatingData || []

  return [
    {
      title: 'Total Claims From Val Terminated',
      t_count: getFormattedCount(terminating[0]),
      t_amount: getFormattedAmount(terminating[0]),
      nt_count: getFormattedCount(nonTerminating[0]),
      nt_amount: getFormattedAmount(nonTerminating[0])
    },
    {
      title: 'Qualified Claims',
      t_count: getFormattedCount(terminating[1]),
      t_amount: getFormattedAmount(terminating[1]),
      nt_count: getFormattedCount(nonTerminating[0]),
      nt_amount: getFormattedAmount(nonTerminating[0])
    },
    {
      title: 'Claims From Output Table',
      t_count: getFormattedCount(terminating[2]),
      t_amount: getFormattedAmount(terminating[2]),
      nt_count: getFormattedCount(nonTerminating[2]),
      nt_amount: getFormattedAmount(nonTerminating[2])
    }
  ]
}

export default function DataChecksSection (props) {
  const dispatch = useDispatch()
  const [expandedPanelName, setExpandedPanelName] = React.useState(false)
  const [graphToggles, setGraphToggles] = React.useState(INITIAL_GRAPH_STATE)

  const completedChecks = !props.postCalc.postCalculationCheckRunning
  const isReplacingIbnr = props.postCalc.replacingIbnrData

  const latestSuccessfulJobId = React.useMemo(() => {
    const successfulJobs = props.jobs.filter(job => {
      const joinedTableNames = job.tableNames.join(',').toLowerCase()

      return (
        joinedTableNames.includes('exp') &&
        joinedTableNames.includes('val')
      )
    })

    const latestJob = successfulJobs[0]

    return latestJob?.id || null
  }, [props.jobs])

  const selectedJob = props.jobs.find(job => job.id === props.postCalc.selectedJobId)
  const isIBNRSuccess = selectedJob?.ibnrSuccess ?? false

  const hasIbnrCalendarMonth = props.postCalc.ibnrData?.some(item => item.calendar_month)

  const columnDefsIbnr = COL_DEFS_IBNR
    .filter(col => col.field !== 'allAggs' || selectedJob?.ibnrAggregators?.length)
    .filter(col => col.field !== 'calendar_month' || hasIbnrCalendarMonth)

  const dropOptions = React.useMemo(() => props.jobs
    .filter(job => job.tableNames.find(name => REGEX_TABLE_EXP.test(name)))
    .map(job => ({
      name: job.id,
      value: job.id,
      label: job.id
    }))
  , [props.jobs])

  const expVsRiskAmountData = React.useMemo(() => {
    return (props.postCalc.expVsRiskAmountCheckData || []).map(data => {
      return {
        ...data,
        exposure: formatNumber(data.exposure),
        'risk history': formatNumber(data['risk history'])
      }
    })
  }, [props.postCalc.expVsRiskAmountCheckData])

  const expVsRiskCountData = React.useMemo(() => {
    return (props.postCalc.expVsRiskCountCheckData || []).map(data => {
      return {
        ...data,
        exposure: formatNumber(data.exposure),
        'risk history': formatNumber(data['risk history'])
      }
    })
  }, [props.postCalc.expVsRiskCountCheckData])

  const lapseData = React.useMemo(() => {
    return (props.postCalc.lapseCheckData || []).map(data => {
      return {
        ...data,
        cnt: formatNumber(data.cnt)
      }
    })
  }, [props.postCalc.lapseCheckData])

  const policyTrackerData = React.useMemo(() => {
    const rowData = props.postCalc.policyTrackerData || []

    return rowData.map((data, index) => {
      const policyTotal = index === 0
        ? {}
        : {
            policy_total: (
              (data.policy_count * 100) /
            rowData[0].policy_count
            ).toFixed(2) + '%'
          }
      return {
        ...data,
        ...policyTotal
      }
    })
  }, [props.postCalc.policyTrackerData])

  const claimsOvsQTerminateData = React.useMemo(() => {
    return (props.postCalc.claimsOvsQTerminatingData || []).map(data => ({
      ...data,
      'amount%': formatNumber(data['amount%']) + '%',
      'amount difference': formatNumber(data['amount difference']),
      'count%': formatNumber(data['count%']) + '%',
      'count difference': formatNumber(data['count difference'])
    }))
  }, [props.postCalc.claimsOvsQTerminatingData])

  const ibnrData = React.useMemo(() => {
    const ibnrAggs = selectedJob?.ibnrAggregators ?? []

    return (props.postCalc.ibnrData || []).map((item, index) => {
      const allAggs = ibnrAggs?.map(agg => item[agg.toLowerCase()]).join(',')
      return {
        ...item,
        newIbnrAmt: '',
        newIbnrCnt: '',
        allAggs,
        id: index
      }
    })
  }, [props.postCalc.ibnrData, selectedJob])

  const nonClaimsOvsQTerminateData = React.useMemo(() => {
    return (props.postCalc.claimsOvsQNonTerminatingData || []).map(data => ({
      ...data,
      claim_amount: formatNumber(data.claim_amount)
    }))
  }, [props.postCalc.claimsOvsQNonTerminatingData])

  const [
    claimsTerminateData,
    nonClaimsTerminateData,
    qualifiedClaimData,
    nonQualifiedClaimData,
    claimOutputData,
    nonClaimOutputData
  ] = React.useMemo(() => {
    return (props.postCalc.claimsTerminatingData && props.postCalc.claimsNonTerminatingData)
      ? [
          props.postCalc.claimsTerminatingData[0] || [],
          props.postCalc.claimsNonTerminatingData[0] || [],
          props.postCalc.claimsTerminatingData[1] || [],
          props.postCalc.claimsNonTerminatingData[1] || [],
          props.postCalc.claimsTerminatingData[2] || [],
          props.postCalc.claimsNonTerminatingData[2] || []
        ].map(data =>
          data.map(row => ({
            ...row,
            claim_amount: formatNumber(row.claim_amount)
          })
          ))
      : [[], [], [], [], [], []]
  }, [
    props.postCalc.claimsTerminatingData,
    props.postCalc.claimsNonTerminatingData
  ])

  const handleAccordionToggle = React.useCallback(name => {
    setExpandedPanelName(prev => prev !== name ? name : '')
  }, [])

  const handleFigureToggle = React.useCallback((name, value) => {
    setGraphToggles(prev => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const handleReplaceIbnr = React.useCallback(async () => {
    await dispatch(replaceIbnr(props.projectId, selectedJob, ibnrData))
  }, [dispatch, props.projectId, selectedJob, ibnrData])

  // TODO: There is currently no test file, needs full coverage

  const isIBNR = IBNRCheck(selectedJob)

  return (
    <div>
      <h1>Data Checks</h1>

      {completedChecks
        ? <>
          <SelectRunCard
            latestRunJobId={latestSuccessfulJobId}
            selectedJobId={props.postCalc.selectedJobId}
            dropOptions={dropOptions}
          />

          <Accordion
            isLoading={props.postCalc.expVsRiskCheckLoading}
            hasError={props.postCalc.errorExpVsRiskCheck}
            chartToggle={graphToggles.exposureVsRisk}
            title='Exposure vs Risk History'
            name='exposureVsRisk'
            selectedName={expandedPanelName}
            onExpandToggle={handleAccordionToggle}
            onChartToggle={handleFigureToggle}
          >
            <Grid>
              <Figure
                title='Risk Amount'
                showChart={graphToggles.exposureVsRisk}
                columnDefs={COL_DEFS_EXP_VS_RISK_AMOUNT}
                data={expVsRiskAmountData}
              />

              <Figure
                title='Risk Count'
                showChart={graphToggles.exposureVsRisk}
                columnDefs={COL_DEFS_EXP_VS_RISK_COUNT}
                data={expVsRiskCountData}
              />
            </Grid>
          </Accordion>

          <Accordion
            isLoading={props.postCalc.lapseCheckLoading}
            hasError={props.postCalc.lapseError}
            name='lapseExclusions'
            selectedName={expandedPanelName}
            title={<>
              Lapse Exclusions

              <Tooltip
                placement="top"
                title="For lapse studies, ESP intentionally removes some exposure and some lapse records.  Lapse exposure starts at the first anniversary within the study period and ends at the last anniversary within the study period.  Actual lapses are removed if the policy would not have exposure at the time of the lapse."
              >
                <InfoIcon />
              </Tooltip>
            </>}
            onExpandToggle={handleAccordionToggle}
            onChartToggle={handleFigureToggle}
          >
            {!props.postCalc.lapseCheckLoading && (
              <Figure
                type='table'
                columnDefs={COL_DEFS_LAPSE_CHECK}
                data={lapseData}
              />
            )}
          </Accordion>

          <Accordion
            title='Expected Rates'
            name='expectedRates'
            selectedName={expandedPanelName}
            onExpandToggle={handleAccordionToggle}
            onChartToggle={handleFigureToggle}
          >
            No expected basis is selected.
          </Accordion>

          <Accordion
            isLoading={props.postCalc.claimsValidationLoading}
            hasError={props.postCalc.errorClaimsValidation}
            title='Claims Validation'
            name='claimsValidation'
            selectedName={expandedPanelName}
            onExpandToggle={handleAccordionToggle}
            onChartToggle={handleFigureToggle}
          >
            <Grid>
              <Row>
                <Figure
                  type='table'
                  columnDefs={COL_DEFS_CLAIM_VALIDATION}
                  data={buildClaimValidationData(props.postCalc)}
                />
              </Row>

              <Figure
                title='Terminating Claims'
                type='table'
                columnDefs={COL_DEFS_CLAIMS_TERMINATE}
                data={claimsTerminateData}
              />

              <Figure
                title='Non Terminating Claims'
                type='table'
                columnDefs={COL_DEFS_CLAIMS_NON_TERMINATE}
                data={nonClaimsTerminateData}
              />

              <Figure
                title='Qualified Claims'
                type='table'
                columnDefs={COL_DEFS_CLAIMS_TERMINATE}
                data={qualifiedClaimData}
              />

              <Figure
                title='Qualified Claims'
                type='table'
                columnDefs={COL_DEFS_CLAIMS_NON_TERMINATE}
                data={nonQualifiedClaimData}
              />

              <Figure
                title='Claims From Output Table'
                type='table'
                columnDefs={COL_DEFS_CLAIM_OUTPUT}
                data={claimOutputData}
              />

              <Figure
                title='Claims From Output Table'
                type='table'
                columnDefs={COL_DEFS_CLAIM_OUTPUT}
                data={nonClaimOutputData}
              />

              <Figure
                title='Output vs Qualified Claims'
                type='table'
                columnDefs={COL_DEFS_CLAIMS_Q_VS_TERMINATE}
                data={claimsOvsQTerminateData}
              />

              <Figure
                title='Output vs Qualified Claims'
                type='table'
                columnDefs={COL_DEFS_CLAIM_OUTPUT}
                data={nonClaimsOvsQTerminateData}
              />
            </Grid>
          </Accordion>

          <Accordion
            isLoading={props.postCalc.policyTrackerLoading}
            hasError={props.postCalc.errorPolicyTrackerCheck}
            chartToggle={graphToggles.policyTracker}
            title='Policy Tracker'
            name='policyTracker'
            selectedName={expandedPanelName}
            onExpandToggle={handleAccordionToggle}
            onChartToggle={handleFigureToggle}
          >
            <Figure
              title='Policy Tracker'
              showChart={graphToggles.policyTracker}
              columnDefs={COL_DEFS_POLICY_TRACKER}
              data={policyTrackerData}
            />
          </Accordion>
          {!isIBNR
            ? <div></div>
            : isIBNRSuccess
              ? <Accordion
                isLoading={props.postCalc.ibnrDataLoading}
                hasError={props.postCalc.errorIbnrData}
                title='IBNR'
                name='Ibnr'
                selectedName={expandedPanelName}
                onExpandToggle={handleAccordionToggle}
              >
                <Figure
                  columnDefs={columnDefsIbnr}
                  data={ibnrData}
                />
                <StyledButton
                  variant='outlined'
                  loading={isReplacingIbnr}
                  loadingPosition="end"
                  endIcon={<ReplaceIcon />}
                  disabled={props.postCalc.ibnrDataLoading || props.postCalc.errorIbnrData || isReplacingIbnr}
                  onClick={handleReplaceIbnr}
                >
                  Replace IBNR
                </StyledButton>
                </Accordion>
              : <Accordion
                  isLoading={props.postCalc.ibnrDataLoading}
                  hasError={true}
                  title='IBNR'
                  name='Ibnr'
                  selectedName={expandedPanelName}
                  onExpandToggle={handleAccordionToggle}
                 >
                    <div>Error Calculating IBNR. Please contact REDSupportTeam@rgare.com.</div>
                </Accordion>
        }

        </>
        : <LoadingLabel
          data-testid='loading-page'
        >Loading...</LoadingLabel>
      }
    </div>
  )
}

DataChecksSection.propTypes = {
  postCalc: PropTypes.object,
  jobs: PropTypes.array,
  ibnrAggs: PropTypes.array,
  projectId: PropTypes.number,
  calculation: PropTypes.object
}
