import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import Headers from './common/headers'
const { skipMapHeaders } = Headers

export default function PrecalcTablesNew ({
  data,
  header,
  studyType,
  gridHeight = 'calc(100vh - 400px)'
}) {
  const [rowData, setRowData] = useState([])
  const [columnDefs, setColumnDefs] = useState([])
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      suppressHeaderMenuButton: true
    }
  }, [])

  const skipMapFlag = studyType === 'skipMap'

  if (data.checkName === 'RISK_RENEWAL_PREM_I') {
    header.push('riskRenewal', 'RowCount', 'TotalRowRenewal')
  }
  if (
    data.nonCritical &&
    !data.checkName.includes('REASONABILITY') &&
    !data.checkName.includes('DOBLEISSUE_')
  ) {
    header = Object.keys(data.results[0])
    header = header.filter(x => x !== 'totalrows' && x !== 'unique_identifier')
    header.unshift('unique_identifier')
  }

  if (data.checkName.includes('MONTHLY_SKEWNESS')) {
    header = ['POLICY_YEAR', 'POlICY_MONTH', 'LAPSE_COUNT']
  }
  if (data.checkName.includes('LAPSE')) {
    header.filter(x => x.toLowerCase() === 'count')
    header.sort()
  }

  const currentSkipMapHeaders =
    skipMapFlag && data && data.results.length > 0
      ? Object.keys(data.results[0]).filter(
        x =>
          !x.toLowerCase().includes('_c') &&
          !x.toLowerCase().includes('overallcount')
      )
      : header

  const skipMapHeader = skipMapHeaders.find(x =>
    data.checkName.includes(x.checkName)
  )

  const skip = skipMapFlag
    ? skipMapHeader
      ? skipMapHeader.headers || []
      : null
    : null

  const headersSkipMap = skip
    ? skip.filter(x => currentSkipMapHeaders.find(w => w === x))
    : null

  const onGridReady = useCallback(
    params => {
      if (data.results.length > 0) {
        const formatColumnDef = []

        ;(!skipMapFlag || data.checkName === 'DUPLICATES_I' || data.checkName === 'DUPLICATES_T'
          ? header
          : headersSkipMap).forEach(coloumnHeader => {
          formatColumnDef.push({
            field: coloumnHeader,
            headerName: coloumnHeader.toUpperCase()
          })
        })
        setRowData(data && data.results ? data.results : null)
        setColumnDefs(formatColumnDef)
        params.api.sizeColumnsToFit()
      }
    },
    [data, header, headersSkipMap, skipMapFlag]
  )
  const gridStyle = useMemo(() => ({ height: gridHeight, width: '100%' }), [
    gridHeight
  ])

  return data && data.results && data.results.length > 0
    ? (
    <div style={gridStyle} id="custom-ag-grid-id" className="ag-theme-alpine">
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        />
    </div>
      )
    : (
    <div></div>
      )
}
PrecalcTablesNew.propTypes = {
  data: PropTypes.any,
  header: PropTypes.any,
  studyType: PropTypes.any,
  gridHeight: PropTypes.any
}
